/*
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2021-09-29 19:13:03
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-02-13 17:01:29
 * @FilePath: \ra-web-admin\src\api\iot\raLane.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

// 查询车道管理列表
export function listRaLane(query) {
  return request({
    url: '/iot/raLane/list',
    method: 'get',
    params: query
  })
}

// 查询车道管理详细
export function getRaLane(id) {
  return request({
    url: '/iot/raLane/' + id,
    method: 'get'
  })
}

// 新增车道管理
export function addRaLane(data) {
  return request({
    url: '/iot/raLane',
    method: 'post',
    data: data
  })
}

// 修改车道管理
export function updateRaLane(data) {
  return request({
    url: '/iot/raLane',
    method: 'put',
    data: data
  })
}

// 删除车道管理
export function delRaLane(id) {
  return request({
    url: '/iot/raLane/' + id,
    method: 'delete'
  })
}

// 导出车道管理
export function exportRaLane(query) {
  return request({
    url: '/iot/raLane/export',
    method: 'get',
    params: query
  })
}

export function getBaiduPoints(id) {
  return request({
    url: '/iot/raLane/getBaiduPoints/' + id,
    method: 'get'
  })
}

export function getLanes() {
  return request({
    url: '/iot/raLane/getLanes',
    method: 'get'
  })
}

// 导入地图数据并同步地图数据到道路管理表中
export function syncMapdataToLane(data) {
  return request({
    url: '/iot/raLane/syncMapdataToLane',
    method: 'post',
    data: data
  })
}
